body {
    font-family: 'Roboto', 'Open Sans', 'Helvetica Nueue', 'Helvetica', 'Arial', sans-serif;
}

.navbar-brand {
    color: #fff;
    font-family: 'Arvo', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: bolder;
    font-size: 24px;
}

.navbar a {
    color: #fff;
}

.navbar a:hover {
    text-decoration: none;
}

.navbar-brand .label {
    font-size: 10px;
    font-family: 'Roboto', 'Open Sans', 'Helvetica Nueue', 'Helvetica', 'Arial', sans-serif;
    line-height: 19px;
    margin-left: .5em;
    text-transform: uppercase;
    vertical-align: middle;
}

/* change the background color */
.navbar-sp-custom {
    background-color: #171717!important;
}
/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
    color: rgba(255,255,255,.8);
}
/* change the link color */
.navbar-custom .navbar-nav .nav-link {
    color: rgba(255,255,255,.5);
}
/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
    color: #ffffff;
}

.dropdown-item { color:#000 !important;}
.dropdown-item:active {background-color: #28a745; color:#fff !important;}


#backdrop:before  {
    background-image: url("/img/board.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    opacity: 0.5;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}


.pointer{
    cursor: pointer;
}

.checkbox-success input[type="checkbox"]:checked + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c; }
.checkbox-success input[type="checkbox"]:checked + label::after {
    color: #fff; }

.popover {
    max-width: 300px;
    width: 300px;
}

.popover-body {
    padding: 0px;
}

.treemap text {
    pointer-events: none;
}

.treemap .grandparent text {
    fill: rgb(33,37,41);
    font-size: 18px;
}

.treemap rect {
    fill: none;
    stroke: #fff;
}

.treemap rect.parent,
.treemap .grandparent rect {
    stroke-width: 2px;
}

.treemap .grandparent rect {
    fill: rgb(255,255,255);
}

.treemap .children rect.parent,
.treemap .grandparent rect {
    cursor: pointer;
}

.treemap .children rect.parent {
    fill: #bbb;
    fill-opacity: .5;
}

.treemap .children:hover rect.child {
    fill: #bbb;
}

.overview h3 {
    margin-bottom: 0px;
}

.overview .table-sm {
    margin-bottom: 0px;
    margin-top: 8px;
}

.schedule .table-sm {
    margin-bottom: 0px;
}

.border-bottom-thick > td {
     border-bottom: 1px #777 solid;
}

.border-top-none > td {
    border-top: 0;
}

.google-logo, .fb-logo {
    height: 32px;
}


.sp-media-item {
    color: #353535;
    min-width: 100px;
    min-height: 100px;
    margin: 10px;
}

.highlight-success{
    outline: none;
    border-color: #28a745;
    box-shadow: 0 0 10px #28a745;
}
