.jumbotron.cover {
    background: none;
    color: #fff;
    height: 700px;
    overflow: hidden;
    padding-top: 250px;
    position: relative;
}

.jumbotron.cover h1,
.jumbotron.cover h3{
    padding: 0px 4px 0px 4px;
    background-color: #171717;
    display: inline-block;
}

.jumbotron.cover:before {
    background: url('../img/trading-floor.jpg') no-repeat;
    background-size: cover;
    content: '';
    opacity: 0.5;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}